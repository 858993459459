.app-container {
  text-align: center;
  overflow: hidden;
  position: absolute;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.about-photo {
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.75);
  max-width: 100%;
}

.about-text {
  color: white;
  font-size: 16px;
  padding: 15px;
}

.album-art {
  width: 15rem;
  margin: 10px 10px 10px 10px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);

  &:hover {
    -webkit-box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.75);
  }
}

.art-container {
  display: flex;
  align-self: center;
  justify-content: center;
}

.available-now {
  margin: 10px 5px;
}

.big-row-spacing {
  display: flex;
  margin: 20px 0px;
  width: 100%;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: rgb(33, 33, 33);
  color: white;
  text-align: center;
}

.footer-logo {
  max-width: 200px;
  margin-bottom: 50px;
}

.footer-text {
  padding: 10px;
  font-size: 12px;
}

.font-outline-black {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.font-outline-white {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.h1-text {
  font-size: 24px;
}

.h2-text {
  font-size: 12px;
}

.hor-font {
  font-family: horizon;
}

.hor-out-font {
  font-family: horizon_outlined;
}

.icon-row {
  margin: 14px 0px 25px 0px;
}

.icon-spacing {
  padding: 0px 8px;
}

.jc-c {
  display: flex;
  justify-content: center;
}

.jc-e {
  display: flex;
  justify-content: flex-end;
}

.jc-s {
  display: flex;
  justify-content: flex-start;
}

.my-container {
  display: flex;
  flex-direction: column;
  margin: 15px auto;
  max-width: 2000px;
}

.name-header {
  width: 100%;
  max-width: 1200px;
}

.photo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0px;
  max-width: 700px;
}

.show-info {
  color: white;
  font-size: 16px;
}

.show-title-container {
  display: flex;
  justify-content: center;
}

.show-line {
  color: white;
  font-size: 18px;
}

.shows-title {
  color: white;
  font-size: 20px;
  padding: 5px 15px;
}

.small-row-spacing {
  display: flex;
  margin: 5px 0px;
  width: 100%;
}

.spotlight-video {
  border-radius: 5px;
  box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.75);
  width: 80vw;
  height: 12rem;
  margin: 15px 0px 15px 0px;
}

.text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ticket-link {
  text-decoration: underline;
}

.video-container {
  display: flex;
  align-self: center;
  justify-content: center;
}

@media only screen and (min-width: 375px) {
  .video-container {
    max-width: 100%;
  }
}

@media only screen and (min-width: 500px) {
  .about-text {
    font-size: 22px;
  }
  .album-art {
    width: 22rem;
  }
  .art-container {
    justify-content: center;
    margin-bottom: 25px;
  }
  .h1-text {
    font-size: 25px;
  }
  .h2-text {
    font-size: 16px;
  }
  .icon-spacing {
    padding: 0px 20px;
  }
  .shows-title {
    font-size: 28px;
  }
  .show-line {
    font-size: 22px;
  }
  .spotlight-video {
    height: 16rem;
  }
}

@media only screen and (min-width: 620px) {
  .h1-text {
    font-size: 28px;
  }
  .h2-text {
    font-size: 20px;
  }
  .spotlight-video {
    height: 20rem;
  }
}

@media only screen and (min-width: 720px) {
  .album-art {
    width: 26rem;
  }
  .about-text {
    font-size: 20px;
  }
  .big-row-spacing {
    display: flex;
    margin: 50px 0px;
    width: 100%;
  }
  .footer-text {
    font-size: 14px;
  }
  .h1-text {
    font-size: 35px;
  }
  .h2-text {
    font-size: 30px;
  }
  .name-header {
    margin-bottom: -25px;
  }
  .spotlight-video {
    height: 24rem;
  }
}

@media only screen and (min-width: 820px) {
  .about-text {
    font-size: 24px;
    max-width: 90%;
  }
  .name-header {
    margin-bottom: -50px;
  }
  .spotlight-video {
    width: 50rem;
    height: 28rem;
  }
}

@media only screen and (min-width: 1200px) {
  .art-container {
    justify-content: flex-end;
    margin-bottom: 0px;
  }
  .h1-text {
    font-size: 40px;
  }
  .h2-text {
    font-size: 26px;
  }
  .name-header {
    margin-bottom: -75px;
  }
  .spotlight-video {
    margin: 0px 0px 0px 0px;
    width: 550px;
    height: 350px;
  }
  .shows-title {
    font-size: 34px;
  }
  .video-container {
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 1600px) {
  .art-container {
    padding-right: 100px;
  }
  .h1-text {
    font-size: 40px;
  }
  .h2-text {
    font-size: 26px;
  }
  .spotlight-video {
    width: 615px;
    height: 375px;
  }
}