html {
  height: -webkit-fill-available;
  /* background: url(./assets/images/coven_web_bg.png) repeat center center fixed;  */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 130vw;
  background-color: #212121;
    /* background-size: cover; */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'horizon';
  src: local('horizon'), url(./assets/fonts/horizon.woff) format('woff');
}

@font-face {
  font-family: 'horizon_outlined';
  src: local('horizon_outlined'), url(./assets/fonts/horizon_outlined.woff) format('woff');
}

@media only screen and (min-width: 550px) {
  html {
    background-size: 100vw;
  }
}

@media only screen and (min-width: 768px) {
  html {
    background-size: 80vw;
  }
}

@media only screen and (min-width: 992px) {
  html {
    background-size: 70vw;
  }
}

@media only screen and (min-width: 1200px) {
  html {
    background-size: 60vw;
  }
}

@media only screen and (min-width: 1600px) {
  html {
    background-size: 800px;
  }
}